import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2025/smile-train-2025/1.jpg';
import blogd2 from '../../assets/images/2025/smile-train-2025/2.jpg';
import blogd3 from '../../assets/images/2025/smile-train-2025/3.jpg';
import blogd4 from '../../assets/images/2025/smile-train-2025/4.jpg';
import blogd5 from '../../assets/images/2025/smile-train-2025/5.jpg';
import blogd6 from '../../assets/images/2025/smile-train-2025/6.jpg';
import blogd7 from '../../assets/images/2025/smile-train-2025/7.jpg';
// import blogd12 from '../../assets/images/2024/senate-committee-commends-mgt/12.jpeg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  LUTH, Smile Train & Prof. Butali Host Joyful Children’s Party
                  Programme
                </h2>
                <div className='blog-one__image'>
                  <img src={blogd2} alt='' />
                </div>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  A Day of Smiles, Stories, and Gratitude at{' '}
                  <strong>Lagos University Teaching Hospital (LUTH).</strong>,
                  in collaboration with Smile Train and Professor Azeez Butali,
                  hosted a heartwarming Children’s Party Programme a celebration
                  of progress, partnership, and the power of a smile.
                  <br />
                  <br />
                  The event kicked off with a warm welcome address by Professor
                  Gbenga Ogunlewe, who graciously introduced the Smile Train
                  team and appreciated the donors whose generosity continues to
                  make a lasting impact on the lives of children and families
                  affected by cleft conditions.
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd7} height={370} />
                  </div>
                  <div
                    className='col-lg-6'
                    style={{ textAlign: 'left', alignItems: 'right' }}
                  >
                    <img src={blogd3} height={370} />
                  </div>
                  <p>
                    The highlight of the day included a presentation by Mrs.
                    Victoria Awazie, who represented Mrs. Nkeiruka Obi, Smile
                    Train’s Vice President and Regional Director for Africa. She
                    shared the core mission of Smile Train and encouraged
                    parents to stay hopeful, emphasizing the life-transforming
                    work the organization has done across the continent.
                    <br />
                    <br />A special moment followed with Professor Azeez Butali,
                    who inspired the audience with personal stories of
                    dedication, achievements, and his deep-rooted relationship
                    with the Smile Train team. His words resonated with both
                    professionals and families, reinforcing the spirit of
                    compassion and commitment that drives the programme.
                  </p>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Nurse Ndidi Enuwa Retires */}
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  In his address, Professor Wasiu Lanre Adeyemo, the Chief
                  Medical Director of LUTH, traced the history of the Smile
                  Train Foundation at LUTH, dating back to 2006. What began as a
                  strictly surgical intervention has now evolved into a
                  multidisciplinary programme, thanks to his visionary advocacy.
                  He highlighted the inclusion of vital professionals such as
                  dentists, orthodontists, dieticians, and others creating a
                  holistic care model for cleft patients. He expressed heartfelt
                  appreciation to the Smile Train team, LUTH management,
                  Professor Butali and his family, Professor Ogunlewe, the
                  ever-dedicated photographer, and all parents present at the
                  event.
                  <br />
                  As the day unfolded, Professor Ogunlewe once again took a
                  moment to recognize and commend the Smile Train team,
                  applauding their dedication and consistent support over the
                  years.
                  <div className='row'>
                    <div className='col-lg-6'>
                      <img src={blogd5} height={370} />
                    </div>
                    <div className='col-lg-6'>
                      <img src={blogd6} height={370} />
                    </div>
                  </div>
                  <br />
                  One of the most touching parts of the celebration came from
                  the mothers, who openly expressed their profound gratitude to
                  Professor Butali and the Smile Train team. Their heartfelt
                  words were a testament to the life changing difference this
                  collaboration has made in their lives and the lives of their
                  children.
                  <br />
                  The event came to a close with an air of joy and celebration,
                  as smiles lit up every face in the room children, parents,
                  professionals, and partners alike.
                  <br />
                  Indeed, it was a day of hope, healing, and happiness
                  celebrating not just the milestones achieved, but the brighter
                  futures ahead.
                  <div className='row'>
                    <div className='col-lg-12'>
                      <img src={blogd4} height={370} />
                    </div>
                  </div>
                </p>

                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Baale of Mashewele community, community elder, HOD of
                  Community Health Dept Prof Ogunnowo, Dr Roberts ,Mushin LGA
                  Health Educator Mrs Akinterinwa and Residents in Community
                  Health.
                </span> */}
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Other pictures as found below:
                </p>
                {/* <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd8} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd9} height={370} />
                  </div>
                </div> */}
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Fig 1: Residents at the department before the program */}
                  <br />
                  {/* Fig 2: Dr Roberts and Dr Balogun Consultants at Dept of Comm
                  Health at the Outreach. */}
                </span>
                {/* <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd10} height={370} width={500} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd11} height={370} />
                  </div>
                </div> */}

                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Residents dispensing medications in advance for the outreach */}
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd1} height={370} width={500} />
                  </div>
                </div>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'></a>
                </p>
              </div>
              <div className='social-block'>
                <a href='#none'>
                  <i className='fab fa-twitter'></i>
                </a>
                <a href='#none'>
                  <i className='fab fa-facebook-f'></i>
                </a>
                <a href='#none'>
                  <i className='fab fa-instagram'></i>
                </a>
                <a href='#none'>
                  <i className='fab fa-dribbble'></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='LAGOS UNIVERSITY TEACHING HOSPITAL | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
